<template>
  <div>
    <div class="row" v-if="filteredImages != ''">
      <div :class="col"
           v-for="(image, index) in filteredImages"
          :key="index">
        <div class="card">
          <!-- Image -->
          <img class="image-request img-fluid"
               :src="thumbnailDir + image.name"
               alt="image"
               @click="showLightbox(image.name)" />

          <div class="container pb-2 pt-2">
            <!-- Approve, Reject, & Report Icon -->
            <div class="">
              <i class="fas fa-flag report-icon"
                 @click="reportImage(image)"
                 title="Report Image">
              </i>
              <i class="fas fa-times reject-icon"
                 @click="updateStatus(image, 'rejected')"
                 title="Reject Image">
              </i>
              <i class="fas fa-check approve-icon"
                 @click="updateStatus(image, 'approved')"
                 title="Aprove Image">
              </i>
            </div>

            <!-- Image Owner Info -->
            <div class="">
              <p class="mb-0 small"> {{ image.studentNickName }} </p>
              <p class="mb-0 small"> Classroom: {{ image.classroomName }} </p>
              <p class="mb-0 small">
                {{ image.created | moment("MMMM D, YYYY") }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <lightbox id="mylightbox"
                ref="lightbox"
                :directory="thumbnailDir"
                :filter="galleryFilter"
                :images="filteredImages"
                :timeoutDuration="5000">
      </lightbox>
    </div>

    <!-- No Image Request -->
    <div class="row" v-else>
      <div class="col">
        <p class="mt-3 text-center">No image requests</p>
      </div>
    </div>
    <div class="row"
          v-if="imagesLastPage && imagesCurrentPage &&
          imagesCurrentPage < imagesLastPage">
      <div class="col text-center">
        <b-button class="btn btn-h3-small btn-h3-blue
                          text-center mt-3 mb-3"
                  type="button"
                  @click="loadMoreImages">
          Load More
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
  const ReportContent = () => import('@/components/modals/ReportContent.vue');

  export default {
    name  : 'ImageRequests',
    props : {
      colSize : Number,
      filter  : Object,
    },
    data() {
      return {
        images            : [],
        imagesPerPage     : 8,
        imagesCurrentPage : 0,
        imagesLastPage    : null,
        thumbnailDir      : process.env.VUE_APP_CONTENT_BASE_URL,
        galleryFilter     : 'all',
        user              : this.$store.getters['user/user'],
      }
    },
    mounted() {
      this.getImageRequests(0);
    },
    watch : {
      filter(value) {
        this.images = [];
        this.imagesCurrentPage = 0;
        if (value)
          this.getFilterResult(value);
        else
          this.getImageRequests(0);
      },
    },
    methods : {

      /**
       * To view image's whole photo
       */
      showLightbox(imageName) {
        this.$refs.lightbox.show(imageName);
      },

      /**
       * Get all pending image requests
       * @param currentPage Pagination's current page
       */
      getImageRequests(currentPage = this.imagesCurrentPage) {
        this.$http.get('api/approval_request/images', {
          params : {
            page     : currentPage + 1,
            perPage  : this.imagesPerPage,
            schoolId : this.user.school_id,
          },
        }).then(response => {
          this.imagesCurrentPage = response.data.currentPage;
          this.imagesLastPage = response.data.lastPage;

          if (this.imagesCurrentPage > 1) {
            const arr1 = this.images;
            const arr2 = response.data.data;

            this.images = arr1.concat(arr2);
          } else this.images = response.data.data;
        }).catch(() => {
          this.images = [];
        });
      },

      /**
       * Approve or Reject Image
       * @param image Image's data
       * @param action Action to make (i.e. 'approved' or 'rejected')
       */
      updateStatus(image, action) {
        this.$http.put('api/approval_request', {
          "status"            : action,
          "userId"            : this.user.user_id,
          "approvalRequestId" : image.approvalRequestId,
        }).then(() => {
          // TODO: find other way to refresh image list
          this.getImageRequests(0); // reset currentPage to 0

          this.$notify({
            group : 'notif',
            title : (action === 'approved') ? 'Approved' : 'Rejected',
            type  : (action === 'approved') ? 'success' : 'warn',
            text  : 'Image from ' + image.studentNickName,
          });
        }).catch(() => {
          this.$notify({
            group : 'notif',
            title : 'Failed',
            type  : 'error',
            text  : 'Oops! Something went wrong.',
          });
        });
      },

      /**
       * Get Image Requests per Filter
       * @param {object} filter Selected filter's data
       */
      getFilterResult(filter) {
        this.$http.get('api/approval_request/ucc/filtered', {
          params : {
            type       : 'ucc_image',
            userId     : filter.user_id,
            schoolId   : this.user.school_id,
            educatorId : this.$store.getters['user/user'].user_id,
            perPage    : this.imagesPerPage,
            page       : this.imagesCurrentPage + 1,
          },
        }).then(response => {
          this.imagesCurrentPage = response.data.currentPage;
          this.imagesLastPage = response.data.lastPage;

          if (this.imagesCurrentPage > 1) {
            const arr1 = this.images;
            const arr2 = response.data.data;

            this.images = arr1.concat(arr2);
          } else this.images = response.data.data;
        });
      },

      /**
       * Report Image
       * @param image
       */
      reportImage(image) {
        this.$modal.show(ReportContent, {
          mediaData : image,
          type      : 'image',
        }, {
          height   : 'auto',
          adaptive : true,
        });
      },

      /**
       * Load More
       */
      loadMoreImages() {
        if (this.filter)
          this.getFilterResult(this.filter)
        else
          this.getImagesRequests();
      },
    },

    computed : {
      filteredImages : function() {
        const result = [];

        if (this.images) {
          for (const element of this.images) {
            result.push({
              id                : element.ucc_id,
              name              : element.path + 'thumbnails/med.jpg',
              approvalRequestId : element.approval_request_id,
              classroomName     : element.classroom_name,
              studentNickName   : element.nickname,
              created           : element.created,
              filter            : 'all',
              url               : element.url,
              userId            : element.user_id,
              guardianId        : element.guardian_id,
            });
          }

          return result;
        }

        return null;
      },

      col() {
        return 'col-' + this.colSize;
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../assets/scss/components/user/moderator/image-request";
</style>
